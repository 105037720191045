import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import EquipmentResource from 'api/equipment';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import EquipmentListItem from 'components/equipment/EquipmentListItem';
import {
  DEFAULT_PAGE_SIZE,
  EQUIPMENT_HEADER,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import { FilterParams } from 'interface/common/filterParam';
import { EquipmentSchema } from 'interface/equipment/equipmentSchema';
import React, { useRef, useState } from 'react';
import { BiImport, BiSearch } from 'react-icons/bi';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormat, DateFormatMDY, formatNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const EquipmentList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [equipmentIds, setEquipmentIds] = useState<any>([]);
  const api = new EquipmentResource();
  let navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    column: string;
    direction: string;
  }>({
    column: '',
    direction: '',
  });

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'equipmentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: useDebounce(filterParams.keyword, 500),
        sort: sortConfig,
      },
    ],
    async () => {
      const direction = sortConfig.direction.toLowerCase() === 'asc' ? '' : '-';

      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
        ordering: direction + sortConfig.column,
      };
      const response = await api.allEquipment(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['Name']: '',
        ['Description']: '',
        ['Code']: '',
        ['Equipment class']: '',
        ['Owner or rent']: '',
        ['Owner']: '',
        ['Physical Location']: '',
        ['Party']: '',
        ['Wet rate']: '',
        ['Dry rate']: '',
        ['Beginning date']: '',
        ['End date']: '',
        ['Equipment Serial number']: '',
        ['Fuel/hour']: '',
        ['Monthly rental']: '',
        ['Wear/hr. Insurance']: '',
        ['Yard Accrual']: '',
        ['R+M/hr']: '',
        ['Note']: '',
        ['Plant']: '',
        ['Equipment Cost']: '',
        ['Budgeted Operating Hours']: '',
        ['Budgeted Idle Hours Month']: '',
        ['Pit']: '',
        ['Operated/Non-Operated']: '',
      },
    ];

    const response = await api.allEquipment({ paginated: false });
    if (response.data.length > 0) {
      if (equipmentIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => equipmentIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['Name']: data?.name || '-',
              ['Description']: data?.description || '-',
              ['Code']: data?.code || '-',
              ['Equipment class']: data?.equipment_class?.class_code || '-',
              ['Owner or rent']: data?.owner_or_rent || '-',
              ['Owner']: data?.owner || '-',
              ['Physical Location']: data?.location || '-',
              ['Party']: data?.party || '-',
              ['Wet rate']: formatNumber(+data?.wet_rate, 2) || '-',
              ['Dry rate']: formatNumber(+data?.dry_rate, 2) || '-',
              ['Beginning date']: DateFormatMDY(data?.beginning_date) || '-',
              ['End date']: DateFormatMDY(data?.end_date) || '-',
              ['Equipment Serial number']: data?.serial_number || '-',
              ['Fuel/hour']: formatNumber(+data?.fuel_per_hour, 2) || '-',
              ['Monthly rental']: formatNumber(+data?.monthly_rental, 2) || '-',
              ['Equipment Cost']: formatNumber(+data?.cost, 2) || 0,
              ['Wear/hr. Insurance']:
                formatNumber(+data?.wear_per_hour_insurance, 2) || 0,
              ['Yard Accrual']: formatNumber(+data?.yard_accural, 2) || 0,
              ['R+M/hr']: formatNumber(+data?.r_m_per_hour, 2) || 0,
              ['Note']: data?.note || '-',
              ['Plant']: data?.plant?.name || '-',
              ['Budgeted Operating Hours']:
                data?.budgeted_operating_hours || '-',
              ['Budgeted Idle Hours Month']:
                data?.budgeted_idle_hours_month || '-',
              ['Pit']: data?.pit || '-',
              ['Operated/Non-Operated']: data?.operated_status || '-',
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['Name']: res?.name || '-',
            ['Description']: res?.description || '-',
            ['Code']: res?.code || '-',
            ['Equipment class']: res?.equipment_class?.class_code || '-',
            ['Owner or rent']: res?.owner_or_rent || '-',
            ['Owner']: res?.owner || '-',
            ['Physical Location']: res?.location || '-',
            ['Party']: res?.party || '-',
            ['Wet rate']: formatNumber(+res?.wet_rate, 2) || '-',
            ['Dry rate']: formatNumber(+res?.dry_rate, 2) || '-',
            ['Beginning date']: DateFormatMDY(res?.beginning_date) || '-',
            ['End date']: DateFormatMDY(res?.end_date) || '-',
            ['Equipment Serial number']: res?.serial_number || '-',
            ['Fuel/hour']: formatNumber(+res?.fuel_per_hour, 2) || '-',
            ['Monthly rental']: formatNumber(+res?.monthly_rental, 2) || '-',
            ['Equipment Cost']: formatNumber(+res?.cost, 2) || 0,
            ['Wear/hr. Insurance']:
              formatNumber(+res?.wear_per_hour_insurance, 2) || 0,
            ['Yard Accrual']: formatNumber(+res?.yard_accural, 2) || 0,
            ['R+M/hr']: formatNumber(+res?.r_m_per_hour, 2) || 0,
            ['Note']: res?.note || '-',
            ['Plant']: res?.plant?.name || '-',
            ['Budgeted Operating Hours']: res?.budgeted_operating_hours || '-',
            ['Budgeted Idle Hours Month']:
              res?.budgeted_idle_hours_month || '-',
            ['Pit']: res?.pit || '-',
            ['Operated/Non-Operated']: res?.operated_status || '-',
          };
        });
      }
    }

    csvExport(dataArray, `Equipment_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(
    () => api.bulkDelete({ equipment_ids: equipmentIds }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('equipmentList');
        setDeleteModalOpen(false);
        toast({
          title: `${equipmentIds.length} Equipment has been deleted.`,
          status: 'success',
          isClosable: true,
        });
        setEquipmentIds([]);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  const handleSort = (column: string) => {
    if (column === sortConfig.column) {
      // Toggle sorting direction if the same column is clicked again
      const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
      setSortConfig({ column, direction: newDirection });
    } else {
      setSortConfig({ column, direction: 'asc' });
    }
  };

  const getSortIcon = (column: string) => {
    if (column === sortConfig.column) {
      return sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />;
    }
    return null;
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Equipment management</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {equipmentIds.length > 0 && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete Equipment ({equipmentIds.length})
              </Button>
            )}
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-equipment-csv"
              modal="equipment"
              setRefetch={setRefetch}
              headerSchema={EQUIPMENT_HEADER}
              label="Import"
            />
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => navigate(routes.manage.equipments.create)}>
              Add Equipment
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={
                        equipmentIds.length ===
                        dataList?.results?.map((equipment: any) => equipment.id)
                          .length
                      }
                      onChange={() => {
                        const equipments = dataList?.results?.map(
                          (equipment: any) => equipment.id
                        );
                        if (equipmentIds.length === equipments.length) {
                          setEquipmentIds([]);
                        } else {
                          setEquipmentIds(equipments);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>Name</Th>
                  <Th onClick={() => handleSort('serial_number')}>
                    Serial number {getSortIcon('serial_number')}
                  </Th>
                  <Th onClick={() => handleSort('code')}>
                    Code {getSortIcon('code')}
                  </Th>
                  <Th>Equipment class</Th>
                  <Th>Owner </Th>
                  <Th>Physical Location</Th>
                  <Th>Description </Th>
                  <Th>Plant </Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: EquipmentSchema, index: number) => (
                      <EquipmentListItem
                        listData={listData}
                        key={listData.id}
                        setEquipmentIds={setEquipmentIds}
                        equipmentIds={equipmentIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={10} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Equipment'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete all ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default EquipmentList;
